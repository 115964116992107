import TokenomicsTable from './TokenomicsTable';

export default function Tokenomics(props){
    return (
        <div className="col-1 p-4 md:mt-32 pt-32 md:pb-32" data-aos="fade-up" data-aos-duration="1000">
            <div class="md:flex">
                
                <div className="md:w-3/6">
                    <h2 className="mb-12 title">CORE IYKYK+<br />NFT-NOMICS</h2>
                    <div>
                        <div>
                            There will only ever be 8,888 available. <br />
                            The minting price increases every 1,111 sold. <br />
                            <br />
                            <br />
                            Holding the core iykyk+ NFT entitles you to receive all future airdrops of the art we commission, and provides access to all future benefits and collaborations with other NFT projects.
                        </div>
                    </div>
                </div>

                <div className="md:w-2/6 md:pl-24">
                    <div className="mt-12 md:mt-0">
                        <TokenomicsTable />
                    </div>
                </div>

                <div className="pt-12 md:pt-0 md:w-1/6 flex items-end">
                    <table className="styled-table">
                        <tr>
                            <th>Minted NFTs</th>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: '#000'}}>Total: 8888 NFTs</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>);
}