import { useEffect, useState, useGlobal } from 'reactn'; 
import { mint, mintWhitelist, startWalletConnection, networkIdToName, isAddressWhitelisted } from '../lib/blockchain';

export default function Mint(props){

    const [onChainData] = useGlobal('onChainData');
    const [minting] = useGlobal('minting');
    const [chainId] = useGlobal('chainId');
    const [qty, setQty] = useState(1);
    const [status, setStatus] = useState('loading...');
    const [ready, setReady] = useState(false);
    
    const init = async () => {
        await setReady(true);
    }

    useEffect(() => {    
        init();
    }, []);

    var mintStatus;

    if(chainId != process.env.REACT_APP_NETWORK){
        mintStatus = 'wrong_network';
    }else if(!onChainData){
        mintStatus = 'loading';
    }else if(onChainData.mintCase == 'paused'){
        mintStatus = 'paused';
    }else if(onChainData.mintCase == 'soldout'){
        mintStatus = 'soldout';
    }else if(onChainData.mintCase == 'whitelist'){
        mintStatus = 'whitelist';
    }else{
        mintStatus = 'mint';
    }
    
    console.log('mintStatus', mintStatus);

    if(!ready && mintStatus != 'connect_wallet') return <div className="mx-auto relative text-center">{status}</div>;

    return (
    
        <div className="mx-auto relative text-center" style={{ height: 300 }}>

            {mintStatus == 'wrong_network' &&
                <div>Wrong network, please switch to {networkIdToName(process.env.REACT_APP_NETWORK)}</div>
            }

            {mintStatus == 'connect_wallet' &&
                <div className="pt-12">
                    <button onClick={() => startWalletConnection()} className="button">Connect your wallet</button>
                </div>
            }

            {mintStatus == 'paused' &&
                <div className="text-xl py-8 text-white mt-12 rounded-xl p-8 max-w-xl mx-auto" style={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
                    Mint not open yet, please check our Discord
                </div>
            }

            {mintStatus == 'soldout' &&
                <div className="text-xl py-8 text-white mt-12 rounded-xl p-8 max-w-xl mx-auto" style={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
                    <div className="text-xl text-indigo-400">We are sold out!</div>
                    <div>You can still grab one from OpenSea</div>
                </div>
            }

            {mintStatus == 'loading' &&
                <div className="mt-12" style={{opacity: 0.5}}>Loading...</div>
            }

            {mintStatus == 'whitelist' && !isAddressWhitelisted() &&
                <div style={{fontSize: '1.3em', color: '#f1ab27'}}>Sorry, you are not on the whitelist</div>
            }

            {(mintStatus == 'mint' || (mintStatus == 'whitelist' && isAddressWhitelisted())) &&
                <div>
                    <div className="mb-8">
                        <span className="text-3xl font-bold" style={{ color: '#96fff8' }}>
                            <div>{onChainData.price} ETH / mint</div>  
                        </span>
                    </div>
                    {!minting &&
                        <div>
                            <div className="flex justify-center mb-4">
                                <div className="flex">
                                    <button className="button-circular" onClick={() => { if(qty > 1) setQty(parseInt(qty) - 1); }}>-</button>
                                    <div className="mint-qty text-center">{qty}</div>
                                    <button className="button-circular" onClick={() => { if(qty < onChainData.max) setQty(parseInt(qty) + 1); }}>+</button>
                                </div>
                            </div>
                            <div className="mt-8">
                                
                                <button onClick={() => {
                                    console.log(mintStatus);
                                    if(mintStatus == 'whitelist') mintWhitelist(qty, onChainData.price); // whitelist
                                    if(mintStatus == 'mint') mint(qty, onChainData.price); // public
                                }} className="link-arrow">Mint</button>

                            </div>
                        </div>
                    }
                    {!!minting && <div>Minting...</div>}
                </div>
            }

        </div>

    );

}
