import './HomeMint.css';
import { useEffect } from 'react';
import { startWalletConnection } from '../lib/blockchain';



export default function HomeMint(){

    useEffect(() => {

        var gradient = new window.Gradient;
		gradient.initGradient("#gradient-canvas");

    }, []);


    return <div className="mint-home flex items-center justify-center">
        
        <div className="md:flex w-2/3 mint-home-content">
            <div className="md:w-1/2 pb-12 md:pb-0">
                <h2 className="title">Mint<br />
                an iykyk+<br />
                core NFT</h2>
            </div>
            <div className="md:w-1/2 items-center justify-center">
                <div className="pb-12">
                As a time limited offer to kickstart the iykyk community, the whitelisted addresses and the first 333 addresses can mint for 0.02 ETH ($23), instead of 0.2 ETH.
                <br /><br />
                The mint is live!
                </div>
                <button onClick={startWalletConnection} className="link-arrow">Mint a Pass</button>
                {/* <button onClick={() => window.open(process.env.REACT_APP_PREMINT_URL, '_blank', 'noopener,noreferrer')} className="link-arrow">Join our premint</button> */}
            </div>
        </div>

        <canvas style={{'--gradient-color-1': '#000', '--gradient-color-2': '#3f44db', '--gradient-color-3': '#c68fff', '--gradient-color-4': '#000'}} id="gradient-canvas" data-transition-in></canvas>

    </div>
}