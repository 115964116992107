export default [
    '0xb6a6ad1a56815cdc3fee1640479d15f7ef8242d5',
    '0xb98296c9b2d75c6c358cc9367acb4ff433f0a742',
    '0xd80266e61dec6427c192efae2dce3b8714e30ade',
    '0x9fa3c87eb4668984b87e185b24ae08759b0f50bd',
    '0xf49937d35b24194f0a34347cb611042862015685',
    '0xe6b953ab1e4f5d503d331bddc4cb84c0a098efb1',
    '0x88e0b2a9e2b877c32fa279681a8c833cc7e6ff6e',
    '0x03d53e5c14bbd9af389c9b8bfff12b091e42abc9',
    '0x245cfa9f8934cfee00f6841a8fd15fce4d7085ff',
    '0xeb668c7b75366968eb30342767fc4fff1b82a25d',
    '0x733cce16a3c3a32645f7f66d0b8eb60aa42e2963',
    '0x2cf6e49aeaec6c715375500d5b81ae1a8397c8af',
    '0xb447b80d1628ab8ba242e3ae9435b7672818f317',
    '0xbb7d53efb7bf9272be64f5164f0bedfabd0c0eda',
    '0xe312504a391575f436ca18539e4476e02d26c96f',
    '0xeddef803e082a879a9e220f564b829d143884908',
    '0xcbdeda54c3b74a82d80f0d56115e2ec4ae62035d',
    '0x73583e53d135aa07c0ba8642508274523c6af0e8',
    '0xcbfc848d35429c89316bdfe43d8b0a4a22446fcd',
    '0xe5f9b9fac7967de6e15374623c8ad3ddac0cf5ac',
    '0xc1b8d586a3d05607f01433df70fda5dac0c19a27',
    '0x3629bb2817395f01e173f9ee121b20eb6bc8c2d6',
    '0x7b44723a44e00429e359f2ca654e8de52e85ca3e',
    '0xc6a4A56c260453fD6e798855edD1Fb4abb200E2B',
    '0xed84c5da813a9f67b7470ae16d87eec2a5fd2f8c',
    '0x0bae2fd474615c52233c001a072f3e10553e26f7',
    '0x1a75efe7d869bfcb011a5b688e1ce37be620384f',
    '0x7a2b4c26fd1cb17c605346bd69340a197f1b55ba',
    '0xd84de7a5a82b254f9c45b1d1de47f73b9afff4cc',
    '0xe7666d6a393b31878d4f3849b04c9f92298c70cf',
    '0x6533d251c0701de61d88b574d5f6ccf3ebc6c1ba',
    '0xaf02a6bebad4bed84a2e410504dd86fc44b859cb',
    '0x7e5c819e87066648cc24c62fb4a54e4ce5a11e2d',
    '0xae0dd1f5a618da0bb40243eb3556097cee5b694e',
    '0x0320d66e19292e7a49a2a6082af737c74f72e2b4',
    '0xe54acc56f63a3216af2675d6c743a17b94407608',
    '0xfaf54838dfdcebeee468dabc2483c641ced428fc',
    '0x13f1b55dec33b4918b05bc9f3da3acfdc8af67ec',
    '0xb82009e58a9b51a3f016d8ef4e7f8b8f419aeb56',
    '0xe825de941249cb510d9987bd4196a708fcc56c67',
    '0x358d0e7b6b643163cb7bb2c9e724e9c973d04ce6',
    '0xa79192c7b4999ce0e1e5412f5e22eb8f8f5d4681',
    '0xc59ab0be2906166d4e6e725fc053d5a060b22d4f',
    '0x15948b41a1f6e7d8d310ea38990189a9962c8204',
    '0xb80f3cd4247fc4d062ea794bdb3690104768367d',
    '0xf6a43821368001ab55fe95a6d42119d4a86b81e6',
    '0x130ab1ba4795f033fcabb3ca6629f695170b791b',
    '0xac24625510359a0d9e7ee2ab9b9515a9d8ea0dfa',
    '0xfa93221ce76e3ee58a1375eafd937cd9f492342f',
    '0xc954e18b30a7b1965c7aa46e839ccd46d92dc4ed',
    '0xa124f768e81b09d03232a7cb9558a1c89e19a76e',
    '0x0c8078555c24c0a967b63f57e253f95f956c9f29',
    '0x28e8c75efb229b5a57b57f5c5c2534cf02596924',
    '0xf1ce83dda084c9d630707dffdfa8bd0f7857b27f',
    '0x26b496c376ceb482a920db62dedc7ca3fdc51e35',
    '0xbcdd9f65bd358b7890260424aa5148d2e59a45a8',
    '0xbc8819dfd5cfd42ba30c5516e62b403156b71ccf',
    '0x6b582839f655076bdecf2fdf7f29f30ac8a6a597',
    '0x3d9ad29f83dd9490b0c1af69315abe1d0022ae3c',
    '0x5BB1468b0519c9A1abbE0C22074CE4862D0862A3',
    '0x36E62878f132FC96F717016dcF7e7d3D946A2dC4',
    '0x7bC2a60C5249C521E7540814718C6adAe0816544',
    '0x6cfd6b3ca3413a3f4BC93642C0B600823dAfbbB9',
    '0xb6A6AD1A56815cDc3Fee1640479d15F7Ef8242d5',
    '0x77Ec4930F8B58d33aDEF61F84e0Fc7cbe929AD2A',
    '0x17Ea40aF13FE2891a142f8F3BAf7E15DAC26C1C4',
]