import heart from '../images/heart.svg';
import logo from '../images/logo.png';

export default function Footer(props){
    return (<div className="md:flex italic pb-60 pt-32">
        <div className="md:w-1/3 pb-12 md:pb-12">
            <img src={logo} style={{width: 100}} />
        </div>
        <div className="md:w-1/3">
            Created by the iykyk+ founders <br />
            with special <img src={heart} class="inline-block ml-2" />
        </div>
        <div className="md:w-1/3">
            For inquiries, please contact contact@iykyk.plus<br /><br />
            <a href={process.env.REACT_APP_DISCORD} target="_blank">Discord</a><br />
            <a href={process.env.REACT_APP_TWITTER} target="_blank">Twitter</a><br />
            <a href={process.env.REACT_APP_INSTAGRAM} target="_blank">Instagram</a><br />
            <br />
            <br />
            &copy; 2022 iykyk.plus
        </div>
    </div>);
}