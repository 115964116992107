import './Roadmap.css';
import roadmap from '../images/roadmap.png';

export default function Roadmap(){
    return (<div className="roadmap col-1 pt-24 pb-24">

        <div className="md:grid md:grid-cols-2 gap-12">
            <div></div>
            <div>
                <h2 className="mb-24 title">Roadmap</h2>
            </div>
        </div>

        <div className="roadmap-img-container">
            <div className="roadmap-img">
                <img src={roadmap} alt="roadmap" class="mx-auto w-full max-w-5xl" />
            </div>
            <div className="roadmap-circle-2" data-aos="fade-up" data-aos-duration="3000"></div>
            <div className="roadmap-circle-1" data-aos="fade-up" data-aos-duration="2000"></div>
        </div>

    </div>);
}
