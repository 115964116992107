import arrow from '../images/text-arrow.png';
export default function TokenomicsTable(props){

    return (
        <table className="styled-table tokenomics-table">
            <thead>
                <tr>
                    <th>NFTs</th>
                    <th>ETH Price</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1,111</td>
                    <td>0.2</td>
                </tr>
                <tr>
                    <td><img src={arrow} className="text-arrow" /> 2,222</td>
                    <td>0.3</td>
                </tr>
                <tr>
                    <td><img src={arrow} className="text-arrow" /> 3,333</td>
                    <td>0.6</td>
                </tr>
                <tr>
                    <td><img src={arrow} className="text-arrow" /> 4,444</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td><img src={arrow} className="text-arrow" /> 5,555</td>
                    <td>1.5</td>
                </tr>
                <tr>
                    <td><img src={arrow} className="text-arrow" /> 6,666</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td><img src={arrow} className="text-arrow" /> 7,777</td>
                    <td>3</td>
                </tr>
                <tr>
                    <td><img src={arrow} className="text-arrow" /> 8,888</td>
                    <td>4</td>
                </tr>
                
            </tbody>
        </table>
    );

}