import { useEffect } from 'react';
import './Hero.css';
import heroImage from '../images/hero1.jpg';
import heroImage2 from '../images/hero2.jpg';
import heroImage3 from '../images/hero3.jpg';
import heroImage4 from '../images/hero4.jpg';
import heroImage5 from '../images/hero5.jpg';
import { startWalletConnection } from '../lib/blockchain';

export default function HeroContent(props){

    useEffect(() => {
        window.VanillaTilt.init(document.querySelectorAll('[data-tilt]'),{
            gyroscope: true,
        });
        window.TyperSetup();
    }, []);

    return (
        <div className="hero p-4">
            <div className="hero-content overflow-x-hidden md:overflow-x-visible md:flex">

                <div className="md:w-2/3 pr-12" data-aos="fade-up">
                    <div className="max-w-2xl">
                        <h2 className="mb-12 title">GOOD NFT ART<br />MADE FOR WEB3<br />
                        <span className="typer-main typer" id="main" data-words="nerds,enthusiasts,artists,developers,producers,creators,collectors,degens" data-delay="100" data-deletedelay="1000"  data-colors="white"></span>
                        <span className="typer-cursor cursor" data-owner="main"></span>
                        </h2>
                        <div className="mb-6 hero-legend">
                            Mint the iykyk+ pass.<br />
                            Receive curated airdrops of up-and-coming artists.<br />
                            Touch grass. Support artists.
                        </div>
                        <div className="hidden md:block pt-12">
                            <div className="flex">
                                <div className="mr-12">
                                    <a href="#section-about" className="link-scroll">Scroll for more</a>
                                </div>
                                <div>
                                    <button onClick={startWalletConnection} className="link-cta">Mint a pass</button>
                                    {/* <a href={process.env.REACT_APP_PREMINT_URL} target="_blank" className="link-cta">Join our premint!</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-32 mb-12 md:mt-0 md:mb-0 md:block md:w-1/3 pl-12 flex justify-end" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="300">
                    <div>
                        
                        <div className="image-card relative" data-tilt data-tilt-full-page-listening style={{transformStyle: 'preserve-3d', transform: 'perspective(1000px)', width: 300, height: 300, borderRadius: 20, backgroundSize: 'cover', backgroundImage: `url(${heroImage})`}}>

                            <div className="image-card" style={{position: 'absolute', right: -60, top: -60, transform: 'translateZ(50px)', width: 200, height: 200, borderRadius: 5, backgroundSize: 'cover', backgroundImage: `url(${heroImage2})`}}></div>
                            <div className="image-card" style={{position: 'absolute', left: -90, bottom: -40, transform: 'translateZ(150px)', width: 150, height: 150, borderRadius: 5, backgroundSize: 'cover', backgroundImage: `url(${heroImage3})`}}></div>
                            <div className="image-card" style={{position: 'absolute', right: -60, bottom: -40, transform: 'translateZ(200px)', width: 90, height: 150, borderRadius: 5, backgroundSize: 'cover', backgroundImage: `url(${heroImage4})`}}></div>
                            <div className="image-card" style={{position: 'absolute', top: -60, left: -50, transform: 'translateZ(30px)', width: 80, height: 120, borderRadius: 5, backgroundSize: 'cover', backgroundImage: `url(${heroImage5})`}}></div>

                        </div>
                        
                    </div>
                </div>
            </div>
        </div>);
}