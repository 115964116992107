import { useGlobal, useEffect, useState } from 'reactn';
import { getNextAirdrop } from '../services/Backend';
import AirdropDetails from './AirdropDetails';
import ArtistDetails from './ArtistDetails';

export default function UpcomingAirdrop(props){

    const [backendReady] = useGlobal('backendReady');
    const [nextAirdrop] = useGlobal('nextAirdrop');
    
    useEffect(() => {
        getNextAirdrop();
    }, []);

    if(nextAirdrop === null) return <div>Loading...</div>;
    
    console.log(nextAirdrop);

    return (<div>
        <div className="text-center">
            <h2 className="text-4xl">Upcoming airdrop</h2>
            <div className="mt-12">
                You are now the owner of an iykyk+ pass NFT! Welcome to the community, we’re so happy you’re here.<br />
                You will automatically receive all future airdrops, as long as you keep your NFT in a wallet address you control.<br />
            </div>
        </div>
        <div className="mt-12">
            {nextAirdrop == 'none' &&
                <div className="text-center">There are no Airdrops scheduled at the moment.</div>
            }
            {nextAirdrop != 'none' &&
                <div>
                    <div className="text-center">As a preview, here is the next iykyk+ artist whose NFT you will be receiving.</div><br />
                    <div className="pb-4">
                        <div className="card">
                            <AirdropDetails airdrop={nextAirdrop} />
                        </div>
                    </div>
                    {nextAirdrop.artist &&
                        <div className="card">
                            <ArtistDetails artist={nextAirdrop.artist} />
                        </div>
                    }
                </div>
            }
        </div>
    </div>)
}