import { useEffect } from 'react';
import Header from './components/Header';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import PageHome from './pages/Home';
import PageAdmin from './pages/Admin';
import PageDashboard from './pages/Dashboard';
import './style.css';
import AOS from 'aos';
import 'aos/dist/aos.css';


function App() {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <BrowserRouter>
            <div className="wrapper">

                <Toaster toastOptions={{ style: { color: '#000', fontWeight: 'bold' }, }} />

                <Header />

                <Routes>
                    <Route path="/" element={<PageHome />} />
                    <Route path="/app" element={<PageDashboard />} />
                    <Route path="admin" element={<PageAdmin />} />
                </Routes>
                
            </div>
        </BrowserRouter>
    );
}

export default App;
