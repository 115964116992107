import { useGlobal, useEffect, useState } from 'reactn';
import { getAdminData } from '../services/Backend';
import ReactModal from 'react-modal';
import axios from 'axios';
import toast from 'react-hot-toast';
import { contractUpdateAddresses, startWalletConnection, runContract, fetchOnChainData } from '../lib/blockchain';
import abiPass from '../lib/abiPass.json';
import AirdropDetails from '../components/AirdropDetails';
import { ethers } from 'ethers';
import ImageUpload from '../components/ImageUpload';

export default function PageAdmin(props){

    const [address] = useGlobal('address');
    const [onChainData] = useGlobal('onChainData');
    const [adminArtists] = useGlobal('adminArtists');
    const [adminAirdrops] = useGlobal('adminAirdrops');
    const [adminWhitelist] = useGlobal('adminWhitelist');
    const [contractAddresses, setContractAddresses] = useGlobal('contractAddresses');
    const [activeAirdrop, setActiveAirdrop] = useState({});
    const [activeArtist, setActiveArtist] = useState({});
    const [showingAirdrop, setShowingAirdrop] = useState({});
    const [artistModalOpen, setArtistModalOpen] = useState(false);
    const [airdropModalOpen, setAirdropModalOpen] = useState(false);
    const [signature, setSignature] = useGlobal('signature');
    const [backendReady] = useGlobal('backendReady');
    const [wlPrice, setWlPrice] = useState(0);
    const [publicPrice, setPublicPrice] = useState(0);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if(!onChainData) return;
        setWlPrice(onChainData.priceWL);
        setPublicPrice(onChainData.pricePublic);
    }, [onChainData]);

    useEffect(() => {
        console.log(adminWhitelist);
        if(!adminWhitelist) return;
        setContractAddresses(adminWhitelist.replace(/,/g, "\n"));
    }, [adminWhitelist]);

    const updateAddresses = async () => {
        contractUpdateAddresses(contractAddresses);
    }

    const updateWlPrice = async () => {
        var price = ethers.utils.parseUnits(parseFloat(wlPrice).toFixed(2), "ether").toHexString();
        var result = await runContract(process.env.REACT_APP_NFT_CONTRACT, abiPass, 'setPriceWL', [price]);
        await fetchOnChainData();
        if(result){
            toast.success('Whitelist mint price updated');
        }else{
            toast.error('Error updating the contract');
        }
    }

    const updatePublicPrice = async () => {
        var price = ethers.utils.parseUnits(parseFloat(publicPrice).toFixed(2), "ether").toHexString();
        var result = await runContract(process.env.REACT_APP_NFT_CONTRACT, abiPass, 'setPricePublic', [price]);
        await fetchOnChainData();
        if(result){
            toast.success('Public mint price updated');
        }else{
            toast.error('Error updating the contract');
        }
    }

    const updateStatus = async () => {
        var newSaleStatus = 1;
        if(onChainData.saleStatus == 1) newSaleStatus = 0;
        var result = await runContract(process.env.REACT_APP_NFT_CONTRACT, abiPass, 'setSaleStatus', [newSaleStatus]);
        await fetchOnChainData();
        if(result){
            toast.success('Sale status updated');
        }else{
            toast.error('Error updating the sale status');
        }
    }

    const updatePause = async () => {
        var result = await runContract(process.env.REACT_APP_NFT_CONTRACT, abiPass, 'flipPaused');
        await fetchOnChainData();
        if(result){
            toast.success('Contract updated');
        }else{
            toast.error('Error updating the contract');
        }
    }

    // artists
    const saveArtist = async () => {
        // validate
        if(!activeArtist.name || activeArtist.name == ''){
            toast.error('Name is required');
            return;
        }

        // check if editing or creating
        if(activeArtist.id){
            await axios.post(process.env.REACT_APP_API + '/artists/' + activeArtist.id + '?signature=' + signature, activeArtist);
        }else{
            await axios.post(process.env.REACT_APP_API + '/artists?signature=' + signature, activeArtist);
        }
        await getAdminData();
        toast.success('Artist saved');
        setArtistModalOpen(false);
    }

    const newArtist = async () => {
        await setActiveArtist({});
        setArtistModalOpen(true);
    }

    const editArtist = async (i) => {
        await setActiveArtist(adminArtists[i]);
        setArtistModalOpen(true);
    }

    const deleteArtist = async (i) => {
        if(!window.confirm('Are you sure you want to delete this artist?')) return;
        await axios.get(process.env.REACT_APP_API + '/artists/' + activeArtist.id + '/delete?signature=' + signature);
        await getAdminData();
        toast.success('Artist deleted');
        setArtistModalOpen(false);
    }


    // airdrops
    const saveAirdrop = async () => {

        // validate
        if(!activeAirdrop.name || activeAirdrop.name == ''){
            toast.error('Name is required');
            return;
        }

        if(!activeAirdrop.artist_id || activeAirdrop.artist_id == ''){
            toast.error('Please select an artist');
            return;
        }

        const formData  = new FormData();

        for (const [key, value] of Object.entries(activeAirdrop)) {
            formData.append(key, value);
        }
        
        if(files.length > 0){
            formData.append('image', files[0]);
        }

        // check if editing or creating
        if(activeAirdrop.id){
            await axios.post(process.env.REACT_APP_API + '/airdrops/' + activeAirdrop.id + '?signature=' + signature, formData);
        }else{
            await axios.post(process.env.REACT_APP_API + '/airdrops?signature=' + signature, formData);
        }

        await getAdminData();
        
        toast.success('Airdrop saved');
        setAirdropModalOpen(false);
        setShowingAirdrop({});

    }

    const newAirdrop = async () => {
        await setActiveAirdrop({});
        setAirdropModalOpen(true);
    }

    const editAirdrop = async i => {
        await setActiveAirdrop(adminAirdrops[i]);
        setAirdropModalOpen(true);
    }

    const deleteAirdrop = async (i) => {
        if(!window.confirm('Are you sure you want to delete this airdrop?')) return;
        await axios.get(process.env.REACT_APP_API + '/airdrops/' + activeAirdrop.id + '/delete?signature=' + signature);
        await getAdminData();
        setActiveAirdrop({});
        toast.success('Airdrop deleted');
        setAirdropModalOpen(false);
        setShowingAirdrop({});
    }

    const signMessage = async (i) => {

        // metamask method
        // ethereum.sendAsync({ 
        //     jsonrpc: '2.0', id: 1, 
        //     method: 'personal_sign', 
        //     params: ['0x6d7367', '0x73120171D5f26C93F7472b0f4F4C94C300264Bcb']
        //    }, (a, x) => console.log(x.result))
        
        console.log('signing....');
        var sig = await window.signer.signMessage('Authenticate as admin');
        await setSignature(sig);
        await getAdminData();
    }

    const setAsNext = async () => {
        await axios.post(process.env.REACT_APP_API + '/airdrops/' + showingAirdrop.id + '/set-as-next?signature=' + signature);
        await getAdminData();
    }

    const triggerAirdrop = async (e) => {
        e.preventDefault();
        console.log('trigger');
    }

    const selectAirdrop = async i => {
        setShowingAirdrop(adminAirdrops[i]);
    }

    if(!address){
        return (<div className="mid-screen-msg">
            Please connect with your wallet to authenticate<br /><br />
            <button onClick={startWalletConnection}>Connect</button>
        </div>);
    }

    
    
    if(address && (address != process.env.REACT_APP_ADMIN_ADDRESS1 && address != process.env.REACT_APP_ADMIN_ADDRESS2 && address != process.env.REACT_APP_ADMIN_ADDRESS3)){
        return (<div className="mid-screen-msg">Unauthorized</div>);
    }

    if(address && !signature){
        return (<div className="mid-screen-msg"><button className="button" onClick={e => signMessage()}>Click here to authenticate</button></div>);
    }

    if(!backendReady || !onChainData){
        return (<div className="mid-screen-msg">loading...</div>);
    }

    // admin
    return (<div className="pt-24">
                <div className="max-w-5xl dashboard mx-auto">
                    
                    <div className="flex">

                        <div className="w-1/4 card bg-slate-900 leading-6">
                            <h3>Contract status</h3>
                            <div>
                                Status: <span className="text-green-500">{onChainData.saleStatus == 0 ? 'whitelist' : 'public'}</span><br />
                                Paused: <span className="text-green-500">{onChainData.paused ? 'yes' : 'no'}</span><br />
                                Price public: <span className="text-green-500">{onChainData.pricePublic} ETH</span><br />
                                Price whitelist: <span className="text-green-500">{onChainData.priceWL} ETH</span><br />
                                Amount minted: <span className="text-green-500">{onChainData.totalSupply}</span><br />
                            </div>
                        </div>

                        <div className="w-2/4 card bg-slate-900">
                            
                            <h3>Update contract</h3>

                            <div className="flex">
                                <div>
                                    <a href="#" onClick={e => updateStatus()} className="button mr-2">Switch Whitelist</a>
                                </div>
                                <div>
                                    <a href="#" onClick={e => updatePause()} className="button mr-2">Switch Pause</a>
                                </div>
                            </div>

                            <div className="sep-top mt-8 mb-8"></div>

                            <div>
                                <div className="mb-1">List of addresses allowed to mint the pass (one per line)</div>
                                <textarea className="w-full h-28 p-3" onChange={e => setContractAddresses(e.target.value)} value={contractAddresses}></textarea>
                                <button onClick={e => updateAddresses()} className="button">Update addresses</button>
                            </div>

                            <div className="sep-top mt-8 mb-8"></div>

                            <div className="mt-6">
                                <div>Price whitelist</div>
                                <input type="text" className="mr-2" onChange={e => setWlPrice(e.target.value)} value={wlPrice} />
                                <button onClick={e => updateWlPrice()} className="button">Update</button>
                            </div>

                            <div className="mt-4">
                                <div>Price public</div>
                                <input type="text" className="mr-2" onChange={e => setPublicPrice(e.target.value)} value={publicPrice} />
                                <button onClick={e => updatePublicPrice()} className="button">Update</button>
                            </div>

                        </div>

                        <div className="w-1/4 card bg-slate-900">
                            <div className="flex justify-between">
                                <h3>Artists</h3>
                                <div>
                                    <a href="#" onClick={e => { e.preventDefault(); newArtist(); }} className="button">New</a>
                                </div>
                            </div>

                            <div>

                                {adminArtists.length == 0 && 
                                    <div className="text-center opacity-50 mt-8">There are no artists</div>    
                                }

                                <ul className="artists-list">
                                    {adminArtists.map((artist, i) => <li key={i}>
                                        <div className="flex justify-between">
                                            <div>
                                                <span className="opacity-100">{artist.name}</span>
                                            </div>
                                            <div>
                                                <button key={i} onClick={() => { editArtist(i);}} className="">
                                                    <span className="pill active hover-opacity">edit</span>
                                                </button>    
                                            </div>
                                        </div>
                                    </li>)}
                                    
                                </ul>
                            </div>

                            
                        </div>

                    </div>

                    <div className="flex">

                        <div className="w-1/3 card bg-slate-900">
                            
                            <div className="flex justify-between">
                                <h3>Airdrops</h3>
                                <div>
                                    <button onClick={e => newAirdrop()} className="button">New airdrop</button>
                                </div>
                            </div>

                            {adminAirdrops.length == 0 &&
                                <div className="text-center opacity-50 mt-4 mb-4">There are no airdrops</div>
                            }
                            
                            {adminAirdrops.length > 0 &&
                                <div>
                                    <ul className="artists-list">
                                        {adminAirdrops.map((airdrop, i) => <li key={i}>
                                            <div className="justify-between flex">
                                                <div>
                                                    <button onClick={() => selectAirdrop(i)}>
                                                        <span className="opacity-100">{airdrop.name}</span>
                                                        {airdrop.is_next == 1 &&
                                                            <span className="pill ml-2 active ghost">next</span>
                                                        }
                                                    </button>
                                                </div>
                                                <div>
                                                    <button key={i} onClick={() => editAirdrop(i)} className="">
                                                        <span className="pill active hover-opacity">edit</span>
                                                    </button>    
                                                </div>
                                            </div>
                                        </li>)}
                                    </ul>
                                    <div className="text-right pagination">
                                        <a href="#" className="mr-2 button small ghost disabled"><i className="bx bx-chevron-left"></i></a>
                                        <a href="#" className="button small ghost"><i className="bx bx-chevron-right"></i></a>
                                    </div>
                                </div>
                            }

                            
                            
                        </div>

                        {showingAirdrop.id &&

                            <div className="w-2/3 card bg-slate-900">

                                <AirdropDetails airdrop={showingAirdrop} />
                                
                                <div className="flex justify-between mt-12">
                                    <div className="">
                                        <button onClick={e => {e.preventDefault(); setAsNext();}} className="button">Set as next</button>
                                    </div>
                                    <div className="">
                                        <button onClick={e => triggerAirdrop(e)} className="button alert">Trigger airdrop</button>
                                    </div>
                                </div>
                                
                            </div>
                            
                        }


                    </div>

                </div>

                <ReactModal isOpen={artistModalOpen} style={modalStyles}>
                    <div className="modal-content">

                        {!!activeArtist.id &&
                            <h3>Edit artist</h3>
                        }
                        
                        {!activeArtist.id &&
                            <h3>New artist</h3>
                        }
                        
                        <button onClick={() => setArtistModalOpen(false)} className="modal-close"><i className="bx bx-x"></i></button>

                        <div className="mt-4">
                            <div>Name</div>
                            <input type="text" className="w-full" onChange={e => setActiveArtist({...activeArtist, name: e.target.value})} value={activeArtist?.name} />
                        </div>          

                        <div className="mt-6">
                            <div>Bio</div>
                            <textarea className="w-full h-24" onChange={e => setActiveArtist({...activeArtist, bio: e.target.value})} value={activeArtist?.bio}></textarea>
                        </div>              

                        <div className="mt-6">
                            <div>Picture URL</div>
                            <input type="text" className="w-full" onChange={e => setActiveArtist({...activeArtist, picture: e.target.value})} value={activeArtist?.picture} />
                        </div>

                        <div className="mt-6">
                            <div>Instagram</div>
                            <input type="text" className="w-full" onChange={e => setActiveArtist({...activeArtist, instagram: e.target.value})} value={activeArtist?.instagram} />
                        </div>

                        <div className="mt-6">
                            <div>Website</div>
                            <input type="text" className="w-full" onChange={e => setActiveArtist({...activeArtist, website: e.target.value})} value={activeArtist?.website} />
                        </div>

                        <div className="flex justify-between mt-8">
                            <div className="flex items-center">
                                {activeArtist.id &&
                                    <button onClick={() => deleteArtist()} className="flex items-center text-red-500 text-2xl"><i className="bx bx-trash"></i></button>
                                }                                
                            </div>
                            <div>
                                <button onClick={() => saveArtist()} className="button">save</button>
                            </div>
                        </div>
                        

                    </div>
                </ReactModal>



                <ReactModal isOpen={airdropModalOpen} style={modalStyles}>
                    <div className="modal-content">

                        {!!activeAirdrop.id &&
                            <h3>Edit airdrop</h3>
                        }
                        
                        {!activeAirdrop.id &&
                            <h3>New airdrop</h3>
                        }
                        
                        <button onClick={() => setAirdropModalOpen(false)} className="modal-close"><i className="bx bx-x"></i></button>

                        <div className="mt-4">
                            <div>Name</div>
                            <input type="text" className="w-full" onChange={e => setActiveAirdrop({...activeAirdrop, name: e.target.value})} value={activeAirdrop?.name} />
                        </div>          

                        <div className="mt-6">
                            <div>Description</div>
                            <textarea className="w-full h-24" onChange={e => setActiveAirdrop({...activeAirdrop, description: e.target.value})} value={activeAirdrop?.description}></textarea>
                        </div>     

                        <div>
                            <div>Artwork</div>
                            <ImageUpload dir={process.env.REACT_APP_BACKEND + '/uploads/airdrops'} value={activeAirdrop.image} onChange={files => setFiles(files)} />
                        </div>

                        <div className="mt-4">
                            <div>Airdrop date</div>
                            <input type="text" className="w-full" onChange={e => setActiveAirdrop({...activeAirdrop, date: e.target.value})} value={activeAirdrop?.date} />
                        </div>   

                        <div className="mt-6">
                            <div>IPFS URL</div>
                            <input type="text" className="w-full" onChange={e => setActiveAirdrop({...activeAirdrop, ipfs_url: e.target.value})} value={activeAirdrop?.ipfs_url} />
                        </div>        
                        
                        <div className="mt-6">
                            <div>Artist</div>
                            {adminArtists && adminArtists.length > 0 &&
                                <select value={activeAirdrop.artist_id} onChange={e => setActiveAirdrop({...activeAirdrop, artist_id: e.target.value})}>
                                    <option value="">Please select</option>
                                    {adminArtists.map((artist, i) => <option key={i} value={artist.id}>{artist.name}</option>)}
                                </select>
                            }
                        </div>
                        
                        <div className="flex justify-between mt-8">
                            <div className="flex items-center">
                                {activeAirdrop.id &&
                                    <button onClick={() => deleteAirdrop()} className="flex items-center text-red-500 text-2xl"><i className="bx bx-trash"></i></button>
                                }                                
                            </div>
                            <div>
                                <button onClick={() => saveAirdrop()} className="button">save</button>
                            </div>
                        </div>
                        

                    </div>
                </ReactModal>


        </div>);

}


const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    content: {
        border: 'none',
        background: 'transparent'
    }
}
