import React, { useState, useEffect } from 'react';


export default props => {

  	const [image, setImage] = useState(null);

  	const selectFile = (e) => {
  		var files = [];
  		for (var i = 0; i < e.target.files.length; i++) {
  			files.push(e.target.files.item(i));
  		}
  		if(e.target.files.length == 1 && e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)){
  			setImage(URL.createObjectURL(e.target.files[0]));
  		}
  		props.onChange(files);
  	}

  	var fileSelector;

  	
	fileSelector = document.createElement('input');
	fileSelector.setAttribute('type', 'file');
	fileSelector.setAttribute('multiple', 'multiple');
	fileSelector.onchange = selectFile;  		
	

  	var thumb_url = null;
  	if(props.value && props.value != ''){
  		thumb_url = props.dir + '/' + props.value;
  	}
  	if(image){
  		thumb_url = image;
  	}


	return (
		<div>
	    	{!!thumb_url &&
	    		<div className="mb-4">
	    			<img src={thumb_url} style={{borderRadius: 10, width: 200}} />
                </div>
	    	}
	    	<button onClick={() => fileSelector.click()} className="button small">{props.label ?? 'Select file'}</button>
	    </div>
  	)

}
