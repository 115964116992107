export default function AirdropDetails(props){

    return (<div>
            <h3>{props.airdrop.name}</h3>
            <div className="flex">
                <div className="w-1/3 pr-2">
                    <img src={process.env.REACT_APP_BACKEND + '/uploads/airdrops/' + props.airdrop.image} className="w-full artist-thumb" />
                </div>
                <div className="w-2/3 pl-4">
                    {props.airdrop.description}
                    {props.airdrop.date != '' &&
                        <div className="mt-12">
                            Airdrop date: {props.airdrop.date}
                        </div>
                    }
                    {props.airdrop.artist &&
                        <div className="sep-top pt-3 mt-3">
                            Artist: {props.airdrop.artist?.name}
                        </div>
                    }
                </div>
            </div>
        </div>);


}