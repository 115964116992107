import team1 from '../images/team1.png';
import team2 from '../images/team2.png';
import team3 from '../images/team3.png';
import team4 from '../images/team4.png';
import arrow from '../images/arrow-down-big.png';

export default function Team(props){

    return (<div className="md:pt-32">
        <div className="md:flex justify-between flex-row-reverse">
            
            <div className="md:w-1/4">
                <h2 className="mb-12 title flex items-center">
                    
                    <div>Team</div>
                    <div className="pl-12">
                        <img src={arrow} className="arrow-title left" />
                    </div>
                </h2>
            </div>

            <div className="md:w-2/4">
                iykyk+ is developed by a husband and wife duo, apollo10 and Jasmine, with starring roles from a wide range of talented developers and artists.
            </div>
            
        </div>
        
        <div className="md:flex team-members">

            <div className="p-12 md:p-0 team-member md:w-1/3">
                <div className="pb-12">
                    <img src={team1} className="rounded-xl" />
                </div>
                <div>
                    <h3 className="font-title gradient-top">apollo10</h3>
                    <div className="pt-8 pr-12 text-gray-400">
                        Apollo10 is a serial entrepreneur, who has built and sold two technology companies.  He’s deeply passionate about bitcoin, blockchain technology, and NFTs as a force for good in the world.
                    </div>
                </div>
            </div>

            <div className="p-12 md:p-0 team-member md:w-1/3">
                <div className="pb-12">
                    <img src={team2} className="rounded-xl" />
                </div>
                <div>
                    <h3 className="font-title gradient-top">Jasmine</h3>
                    <div className="pt-8 pr-12 text-gray-400">
                        Jasmine is a former CMO at a tech startup, who was bitcoin red-pilled and sucked into the web3 rabbit hole.
                    </div>
                </div>
            </div>
            <div className="p-12 md:p-0 team-member md:w-1/3">
                <div className="pb-12">
                    <img src={team3} className="rounded-xl" />
                </div>
                <div>
                    <h3 className="font-title gradient-top">Agu</h3>
                    <div className="pt-8 pr-12 text-gray-400">
                        Agu is a web3  /  blockchain developer based in Amsterdam. He spends most <br />
                        of his days building minting dapps or DeFi projects, and thinking of new ways to create and contribute to the web3 space.
                    </div>
                </div>
            </div>
        </div>
    </div>);

}