import { useGlobal } from 'reactn';
import logo from '../images/logo.png';
import { startWalletConnection, formatAddress } from '../lib/blockchain';
import { Link } from "react-router-dom";

export default function Header(props){

    const [address] = useGlobal('address');

    return (<div className="header">
        <div className="header-content">
            <Link to="/">
                <div className="logo"><img src={logo} /></div>
            </Link>
            <div className="flex">
                
                <a href="https://museum.iykyk.plus/" target="_blank" className="header-button hidden md:block">Upcoming iykyk+ Exhibition in May 2023, London</a>

                <div className="social mr-4">
                    <a href={process.env.REACT_APP_DISCORD} target="_blank">
                        <i className="bx bxl-discord"></i>
                    </a>
                    <a href={process.env.REACT_APP_TWITTER} target="_blank">
                        <i className="bx bxl-twitter"></i>
                    </a>
                    <a href={process.env.REACT_APP_INSTAGRAM} target="_blank">
                        <i className="bx bxl-instagram"></i>
                    </a>
                </div>
                <div className="address">
                    {address &&
                        <div>{formatAddress(address)}</div>
                    }
                    {!address &&
                        <div>
                            {/* <a href="#" onClick={e => { e.preventDefault(); startWalletConnection(); }} className="button disabled">Connect<span className="hidden md:inline-block">&nbsp;wallet</span></a> */}
                        </div>
                    }
                </div>
            </div>
        </div>
        <div className="mt-1 block md:hidden">
            <a href="https://museum.iykyk.plus/" target="_blank" className="header-button small">Upcoming iykyk+ Exhibition in May 2023, London</a>
        </div>
    </div>);
}