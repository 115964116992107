export default function ArtistDetails(props){

    return (<div>
            <h3 style={{fontSize: '1em', fontWeight: 'bold'}}>by {props.artist.name}</h3>
            <div className="flex">
                <div className="w-1/3 pr-2">
                    <img src={props.artist.picture} className="w-full artist-thumb" />
                </div>
                <div className="w-2/3 pl-4">
                    
                    {props.artist.bio}
                    
                    <div className="artist-social sep-top pt-3 mt-3">
                        <a href={props.artist.instagram} className="mr-2" target="_blank"><i className="bx bxl-instagram"></i></a>
                        {props.artist.twitter && <a href={props.artist.twitter} className="mr-2" target="_blank"><i className="bx bxl-twitter"></i></a>}
                        <a href={props.artist.website} className="mr-2" target="_blank"><i className="bx bxs-home"></i></a>
                    </div>
                    
                </div>
            </div>
        </div>);


}