import { useGlobal } from 'reactn';
import { useLocation } from 'react-router-dom';
import Mint from '../components/Mint';
import Hero from '../components/Hero';
import About from '../components/About';
import UpcomingAirdrop from '../components/UpcomingAirdrop';
import Roadmap from '../components/Roadmap';
import Dates from '../components/Dates';
import FAQ from '../components/FAQ';
import Team from '../components/Team';
import HomeMint from '../components/HomeMint';
import Tokenomics from '../components/Tokenomics';
import Footer from '../components/Footer';



export default function PageHome(props){

    const location = useLocation()
    const params = new URLSearchParams(location.search)


    const [onChainData] = useGlobal('onChainData');
    const [address] = useGlobal('address');

    return (<div className="pt-24">
                            
            {!address &&
                <div>

                    <div>
                        <Hero />
                    </div>

                    <div id="section-about" className="col-1" data-aos="fade-up" data-aos-duration="1000">
                        <About />
                    </div>

                    <div className="md:pt-8">
                        <div data-aos="fade-up" data-aos-duration="1000">
                            <Roadmap />
                        </div>
                        <div data-aos="fade-up" data-aos-duration="1000">
                            <Dates />
                        </div>
                    </div>

                    <div>
                        <Tokenomics />
                    </div>
                    

                    <div className="col-1" data-aos="fade-up" data-aos-duration="1000">
                        <HomeMint />
                    </div>

                    <div className="col-1 pb-32" data-aos="fade-up" data-aos-duration="1000">
                        <FAQ />
                    </div>

                    <div className="col-1 md:pb-32" data-aos="fade-up" data-aos-duration="1000">
                        <Team />
                    </div>

                    <div className="col-1">
                        <Footer />
                    </div>

                </div>
            }
            
            {onChainData?.balance == 0 &&
                <div className="mt-32">
                    <h3 className="text-center text-xl mb-12">Mint the iykyk+ pass NFT. Receive curated<br />
                    airdrops of up-and-coming artists. Touch grass.<br />
                    Support artists.</h3>
                    <Mint />          
                </div>
            }

            {onChainData?.balance > 0 &&
                <div className="max-w-4xl mx-auto">
                    <UpcomingAirdrop />
                </div>
            }

        </div>);

}