import toast from 'react-hot-toast';
import { setGlobal, getGlobal } from 'reactn';


export async function getAdminData(){

    await setGlobal({ backendReady: false });
    
    // get admin data
    var response = await fetch(process.env.REACT_APP_API + '/admin-data?signature=' + getGlobal().signature);
    var data = await response.json();

    if(data.error){
        toast.error(data.error);
        return;
    }

    await setGlobal({
        adminArtists: data.artists,
        adminAirdrops: data.airdrops,
        adminWhitelist: data.whitelist,
        backendReady: true
    });
    
}


export async function getNextAirdrop(){

    await setGlobal({ backendReady: false });

    // get admin data
    var response = await fetch(process.env.REACT_APP_API + '/next-airdrop');
    var data = await response.json();

    if(data.error){
        toast.error(data.error);
        return;
    }

    await setGlobal({
        nextAirdrop: data.nextAirdrop,
        backendReady: true
    });
}
