import './Dates.css';

export default function Dates(props){

    return (<div className="dates col-1">

            <div className="date-entry" data-aos="fade-up" data-aos-duration="1000">
                <div className="date">
                    <div className="day">31</div>
                    <div className="month">Oct.</div>
                </div>
                <div className="date-details">
                    We start accepting<br />
                    Premint applications
                </div>
            </div>
                                    
            <div className="date-entry" data-aos="fade-up" data-aos-duration="1000">
                <div className="date">
                    <div className="day">25</div>
                    <div className="month">Nov.</div>
                </div>
                <div className="date-details">
                    Iykyk+ passes<br />
                    Are available to mint
                </div>
            </div>

            <div className="date-entry" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                <div className="date">
                    <div className="day">7</div>
                    <div className="month">Dec.</div>
                </div>
                <div className="date-details">
                    1st Iykyk+<br />
                    NFT airdrop
                </div>
            </div>

            

        </div>);

}