import { useGlobal } from 'reactn';
import Mint from '../components/Mint';
import UpcomingAirdrop from '../components/UpcomingAirdrop';
import { startWalletConnection } from '../lib/blockchain';

export default function PageDashboard(props){

    const [address] = useGlobal('address');
    const [onChainData] = useGlobal('onChainData');
    
    return (<div className="pt-24">

        {!address &&
            <div className="pt-32 text-center">
                <div className="mb-12">Connect your wallet to access your dashboard</div>
                <a href="#" onClick={e => { e.preventDefault(); startWalletConnection() }} className="button big">Connect wallet</a>
            </div>
        }

        {onChainData?.balance == 0 &&
            <div className="mt-32">
                <h3 className="text-center text-2xl mb-12">Mint the iykyk+ NFT. Receive curated<br />
                airdrops of up-and-coming artists. Touch grass.<br />Support artists.</h3>
                <Mint />        
            </div>
        }

        {onChainData?.balance > 0 &&
            <div className="max-w-4xl mx-auto">
                <UpcomingAirdrop />
            </div>
        }    
    </div>);
}