import './FAQ.css';
import { useEffect } from 'react';
import ReactFaq from 'react-faq-component';
import arrow from '../images/arrow-down-big.png';

const data = {
    rows: [
      {
        title: "How can I get the iykyk+ core NFT?",
        content: "Currently the only place to get a iykyk+ core NFT is minting on this site. Eventually, you’ll also be able to buy one on a secondary market like LooksRare or OpenSea."
      },
      {
        title: "What kind of art will I get by holding the NFT?",
        content: "We’re inspired by art that’s aesthetically and intellectually beautiful, but anything that makes us say “wow”, and involves the theme “if you know you know”."
      },
      {
        title: "How often do the airdrops go out?",
        content: "Our goal is to find and commission as many great artists as possible. If you know a great artist you’d like to introduce to the project, please let us know!"
      },
      {
        title: "How can I help support artists through iykyk+?",
        content: `<i>Currently three ways:</i> <br /><br />
        If you get an airdrop of art that you love, please tell your friends about the artists and follow them on social media.<br /><br />
        When you buy and sell iykyk+ commissioned NFTs, the artist receives 100% of the proceeds of the first 10 sales, and then 75% of secondary market NFT sales in perpetuity.<br /><br />
        When you mint or buy a iykyk+ core NFT, one of the primary ways we use the proceeds is commissioning art, ensuring a healthy supply of inspiring art from creative artists.  
        `
      },
      {
        title: " Can I submit my idea or art for consideration?",
        content: `Absolutely! We’d love to hear from you. Please email us at <a href="mailto:${process.env.REACT_APP_ARTISTS_EMAIL}">${process.env.REACT_APP_ARTISTS_EMAIL}</a>.`
      },
      {
        title: "Does it have to be purely digital?",
        content: "If it’ll make us say “wow!”, lets talk."
      },
      {
        title: "How much is the iykyk+ team involved in art direction?",
        content: `We enjoy collaborating with artists, and developing ideas together. However, the fun of commissioning artists is letting them go wild and seeing what they create.<br />
        Rest assured, we’ll never request a pixel to be moved, or for things to “pop”.
        `
      },
      {
        title: "Is there anywhere in the real world we can see a display of iykyk+ art?",
        content: "Our dream is to have a network of physical art galleries in cities we love, like Amsterdam, New York, Lisbon and Tokyo."
      },
      {
        title: "Aren’t NFTs bad for the environment?",
        content: `The iykyk+ project is based on Ethereum, which recently reduced energy usage over 99% through transitioning to a proof-of-stake consensus mechanism.
        We’re tremendously excited about the plethora of technological innovations afforded by NFT technology, including how art is made and how artists are compensated.`
      },
      {
        title: "How can I contact the founders or the artists?",
        content: `Come and hang out in our <a href="${process.env.REACT_APP_DISCORD}">Discord channel</a>! We’d love to see you there.`
      },
      {
        title: "Wen moon?",
        content: `Ask not what your NFT project can do for you, ask what you can do for your NFT project. We’re doing our best every day to meet and commission amazing artists and to spread the word.<br /><br />
            You can help us by inviting your friends to join our <a href="${process.env.REACT_APP_DISCORD}">Discord channel</a>, and sharing the iykyk+ NFTs you love with your friends and your socials.`
      },{
        title: "What license will the NFTs be under?",
        content: `Unless otherwise noted, the minted NFTs fall under the a16z “Can’t Be Evil” CBE-NECR-HS license.`
      },
      
    ]
  }

export default function FAQ(props){

    useEffect(() => {
        // setTimeout(() => {
        //     document.querySelectorAll('.row-title').forEach(e => {
        //         e.classList.add('row-title', 'expanded');
        //         e.classList.remove('closed');
        //     });
        //     document.querySelectorAll('.row-content').forEach(e => {
        //         e.styles.height = '100%';
        //     });
        // }, 2000);
    }, []);

    return (
        <div className="pt-32">
            <h2 className="mb-12 title flex items-center">
                <div className="pr-12">
                    <img src={arrow} className="arrow-title" />
                </div>
                <div>F.A.Q.</div>
            </h2>
            <ReactFaq
            config={{openOnload: true}}
            data={data}
            styles={{
                titleTextColor: "#fff",
                rowTitleColor: "#fff",
                rowContentColor: "#888",
                bgColor: "transparent",
                arrowColor: "#fff",
            }} 
            />
        </div>
    );
}