import { setGlobal, getGlobal } from 'reactn';
import { ethers } from 'ethers';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import abiPass from './abiPass.json';
import toast from 'react-hot-toast';
import JSConfetti from 'js-confetti';
import axios from 'axios';
import { getAdminData } from '../services/Backend';
import whitelist from './whitelist';


const jsConfetti = new JSConfetti();
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider, // required
        options: {
            infuraId: process.env.REACT_APP_INFURA_ID // required
        }
    },
};

const web3Modal = new Web3Modal({
    cacheProvider: false, // optional
    theme: "dark",
    providerOptions, // required
    disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
});

export function isAddressWhitelisted(){
    if(!getGlobal().address || getGlobal().address === '') return false;
    var address = getGlobal().address;

    return whitelist.map(e => e.toLowerCase()).includes(address.toLowerCase());
}

export async function contractUpdateAddresses(addresses){

    // save addresses to backend
    var commaAddresses = addresses.replace(new RegExp("[\r\n]", "gm"), ","); // replace new lines for commas
    var arrAddresses = commaAddresses.split(',');

    // calculate merkle tree root
    const leafNodes = arrAddresses.map(addr => keccak256(addr));
    const tree = new MerkleTree(leafNodes, keccak256, { sortPairs: true});
    const root = tree.getRoot().toString('hex');

    console.log(root);

    var result = await runContract(process.env.REACT_APP_NFT_CONTRACT, abiPass, 'setMerkleRootWL', ['0x' + root]);
    if(result){
        var data = {
            label: 'whitelist',
            value: commaAddresses 
        }
        await axios.post(process.env.REACT_APP_API + '/settings/update?signature=' + getGlobal().signature, data);
        await getAdminData();
        toast.success('Whitelist updated');
    }


}



export function formatAddress(address) {
    return address.substring(0, 6) + '...' + address.substring(38, 42);
}


export function networkIdToName(id){
    if(parseInt(id) === 1) return 'Ethereum mainnet';
    if(parseInt(id) === 4) return 'Rinkeby';
    if(parseInt(id) === 3) return 'Ropsten';
    if(parseInt(id) === 5) return 'Goerli';
    if(parseInt(id) === 42) return 'Kovan';
}


export async function startWalletConnection(){
    var connected = await connectWallet();
    if(!connected) return;
    await fetchOnChainData();
}




export async function connectWallet(){

    try {
        web3Modal.clearCachedProvider();
        var web3modalInstance = await web3Modal.connect();
    } catch(e) {
        console.log("Could not get a wallet connection", e);
        return false;
    }

    window.provider = new ethers.providers.Web3Provider(web3modalInstance);
    window.signer = window.provider.getSigner();

    // Subscribe to network/accounts change
    window.provider.on('chainChanged', id => {
        document.location.reload();
    });
    
    window.provider.on('accountsChanged', function (accounts) {
        document.location.reload();
    });

    var accounts = await window.provider.listAccounts();
    var network = await window.provider.getNetwork();
    var chainId = parseInt(network.chainId);


    if(chainId != process.env.REACT_APP_NETWORK){
        toast.error('Wrong network, please connect your wallet to ' + networkIdToName(process.env.REACT_APP_NETWORK));
        return false;
    }

    await setGlobal({ address: accounts[0] });
    await setGlobal({ chainId: chainId });

    return true;
}


export async function fetchOnChainData(){

    const toastId = toast.loading('loading on-chain data...');
    
    if(getGlobal().chainId != process.env.REACT_APP_NETWORK) return ['wrong network', 0];

    var contract = new ethers.Contract(process.env.REACT_APP_NFT_CONTRACT, abiPass, window.provider);
    // checks if the user already own an nft
    var balance = await contract.balanceOf(getGlobal().address);
    balance = balance.toNumber();
    console.log('balance', balance);
        
    // retrieve contract info
    var totalSupply = await contract.totalSupply();
    var maxSupply = await contract.maxSupply();
    var paused = await contract.paused();
    var saleStatus = await contract.saleStatus();
    var maxPerTxPublic = await contract.maxPerTxPublic();
    
    
    var mintCase = 'public';
    if(saleStatus == 0) mintCase = 'whitelist';
    if(paused) mintCase = 'paused';
    else if(totalSupply == maxSupply) mintCase = 'soldout';
    
    console.log('mintcase from blockchain');
    console.log(mintCase);

    var priceWL = await contract.getPriceWL();
    priceWL = ethers.utils.formatEther(priceWL);

    var pricePublic = await contract.getPricePublic();
    pricePublic = ethers.utils.formatEther(pricePublic);

    var price = saleStatus == 0 ? priceWL : pricePublic;

    var onChainData = {
        totalSupply: parseInt(totalSupply),
        maxSupply: parseInt(maxSupply),
        paused: paused,
        priceWL: priceWL,
        pricePublic: pricePublic,
        price: price,
        balance: balance,
        max: parseInt(maxPerTxPublic),
        left: maxSupply - totalSupply,
        mintCase: mintCase,
        saleStatus: saleStatus.toNumber()
    }

    await setGlobal({ onChainData });    

    toast.dismiss(toastId);

}


export async function mintWhitelist(qty, price){
    
    if(!isAddressWhitelisted()) return false;

    var addressLC = getGlobal().address.toLowerCase();
    var whitelistLC = whitelist.map(e => e.toLowerCase());

    const leafNodes = whitelistLC.map(addr => keccak256(addr));
    const tree = new MerkleTree(leafNodes, keccak256, { sortPairs: true});
    const root = tree.getRoot().toString('hex');
    
    console.log(root);
    
    const claimingAccount = leafNodes[whitelistLC.indexOf(addressLC)];
    const hexProof = tree.getHexProof(claimingAccount);
    
    var contract = new ethers.Contract(process.env.REACT_APP_NFT_CONTRACT, abiPass, window.provider);
    var contractWithSigner = contract.connect(window.signer);

    // listen to a contract event when the transaction is finished
    contract.once("Minted", (caller, event) => {
        if(caller !== getGlobal().address) return;
        setGlobal({minting: false});
    });

    var amount = qty * price;
    amount = amount.toFixed(4);
    console.log(amount);
    
    const toastId = toast.loading('Minting...');
    setGlobal({minting: true});

    try{
        
        var tx = await contractWithSigner.mintWL(qty, hexProof, {from: getGlobal().address, value: ethers.utils.parseUnits(amount, "ether").toHexString()});
        if(tx.wait) await tx.wait();    
        
        toast.dismiss(toastId);
        toast.success('Minting successful!');
        setGlobal({minting: false});
        jsConfetti.addConfetti();
        fetchOnChainData();
        
    }catch(e){

        toast.dismiss(toastId);
        setGlobal({minting: false});
        showError(e);
        
    }

}



export async function mint(qty, price){
    
    qty = parseInt(qty);
    
    // call mint from contract
    var contract = new ethers.Contract(process.env.REACT_APP_NFT_CONTRACT, abiPass, window.provider);
    var contractWithSigner = contract.connect(window.signer);
    var maxPerTxPublic = await contract.maxPerTxPublic();

    console.log('minting, contract: ' + process.env.REACT_APP_NFT_CONTRACT);

    if(!Number.isInteger(qty) || qty <= 0){
        toast.error('Quantity not valid');
        return;
    }

    if(qty > maxPerTxPublic){
        toast.error('Sorry, max ' + maxPerTxPublic + ' per transaction');
        return;
    }

    // listen to a contract event when the transaction is finished
    contract.once("Minted", (caller, event) => {
        if(caller !== getGlobal().address) return;
        setGlobal({minting: false});
        // toast.success('Mint successful!');
    });

    
    var amount = qty * price;
    amount = amount.toFixed(2);
    
    const toastId = toast.loading('Minting...');
    setGlobal({minting: true});

    try{
        var tx = await contractWithSigner.mintPublic(qty, getGlobal().address, {from: getGlobal().address, value: ethers.utils.parseUnits(amount, "ether").toHexString()});
        console.log('tx:', tx);
        if(tx.wait) await tx.wait();    
        
        toast.dismiss(toastId);
        toast.success('Minting successful!');
        setGlobal({minting: false});
        jsConfetti.addConfetti();
        fetchOnChainData();

    }catch(e){

        toast.dismiss(toastId);
        setGlobal({minting: false});
        showError(e);

    }


}


export async function runContract(contract, abi, func, args = [], event = '', eventCallback = () => {}){
    
    if(!window.signer) return;
    
    var contract = new ethers.Contract(contract, abi, window.provider);
    var contractWithSigner = contract.connect(window.signer);
    
    if(event != ''){
        contract.on(event, eventCallback);
    }

    const toastId = toast.loading('waiting for transaction...');
    
    try{
        if(event != ''){
            await contractWithSigner[func](...args);
        }else{
            var tx = await contractWithSigner[func](...args);
            if(tx.wait) tx = await tx.wait();    
            toast.dismiss(toastId);
        }
        return true;
    }catch(e){

        toast.dismiss(toastId);
        showError(e);
        return false;
    }
}



function showError(e){

    console.log(e);

    var error = '';

    if(e.data && e.data.message){
        error = e.data.message;
    }

    if(e.error && e.error.message){
        error = e.error.message;
    }

    error = error.replace('execution reverted: ', '');
    
    if(error.includes('insufficient funds')){
        error = 'Insufficient funds';
    }

    if(error.includes('INVALID_OPERATOR')){
        error = 'Incubator not approved';
    }

    if(error == ''){
        toast.error('please try again');
        return;
    }
    
    toast.error(error);
    

}





export async function remainingWithoutMinting(){
    var remaining = {};
    var issues = getGlobal().issues;
    if(!issues) return;
    for(var issue of issues){
        var contract = new ethers.Contract(issue.contractAddress, abiPass, window.provider);
        if(issue.contractAddress == process.env.REACT_APP_NFT_CONTRACT) return 0;
        try{
            var left = await contract.remaining();
            remaining[issue.contractAddress] = parseInt(left);
        }catch(e){
            console.log('error getting remaining');
            console.log(e);
        }
    };
    setGlobal({remaining: remaining});
}



