export default function About(props){
    return (
        <div>

            <div className="md:grid grid-cols-2 gap-12">
                <div data-aos="fade-up" data-aos-duration="1000">
                    <h2 className="mb-8 title gradient-top">Artists<br />first</h2>
                    <p>
                        We want artists to directly benefit from their creative efforts, so 75% of secondary NFT sales revenues go directly to the artist, in addition to a commission fee.
                    </p>
                </div>

                <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                    <h2 className="mt-12 md:mt-0 mb-8 title gradient-top">WEB3 ART<br />COMMUNITY</h2>
                    <p>
                        iykyk+ is a community of artists and collectors who appreciate new forms of art and creativity.
                    </p>
                </div>
                <div className="w-full col-span-2 pt-12 md:pt-24" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000">
                    <h2 className="mb-8 title gradient-top">Join<br />the community</h2>
                    <div className="flex mt-12">
                        <div className="w-1/2" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
                            <p>
                                Interact with artists and project founders. Come and talk art,<br />
                                collaborations, new trends in Web3 and the digital world.<br />
                                You'll also get previews of upcoming commissions and NFT drops.
                            </p>
                        </div>
                        <div className="w-1/2 pl-6" data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000">
                            We do our best to make the iykyk+ community friendly<br />
                            and welcoming, so please drop by and say hello!<br />
                            <b>We'd love to see you in there.</b>
                        </div>
                    </div>
                </div>
                <div></div>
                <div className="pt-12 md:pt-0" data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000">
            
                    <a href={process.env.REACT_APP_DISCORD} target="_blank" class="link-arrow">Join the Discord</a>
            
                </div>

            </div>
            
        </div>
    )

}